.Notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  transform: translateY(-100%);
  transition: all 0.2s;
}
.Notification.true {
  transform: translateY(0);
}
.Notification .container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-green);
  padding: 10px 20px;
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
  width: auto;
  font-size: 16px;
  font-weight: 500;
}
.Notification.error .container {
  background-color: var(--color-red);
}
.Notification .container .icon {
  margin-right: 10px;
}
