.UploadImage {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-bottom: 150px;
}
.UploadImage .header {
  margin-bottom: 50px;
}
.UploadImage .header .title {
  color: white;
}

.UploadImage .wrapper .square {
  width: 100%;
}

.UploadImage .wrapper .dropzone {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
  font-weight: 500;
}
.UploadImage .wrapper .dropzone:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.UploadImage .wrapper .result {
  margin-top: 50px;
}
.UploadImage .wrapper .result .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
}
.UploadImage .wrapper .result .container:last-child {
  border: none;
}
.UploadImage .wrapper .result .container .left {
  display: flex;
  align-items: center;
}
.UploadImage .wrapper .result .container .left img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.UploadImage .wrapper .result .container .left .title {
  font-weight: 500;
}
.UploadImage .wrapper .result .container .left .size {
  font-size: 13px;
}
.UploadImage .wrapper .result .container .right select,
.UploadImage .wrapper .result .container .right input {
  background: #111;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
}
.UploadImage .wrapper .result .container .right select:focus,
.UploadImage .wrapper .result .container .right input:focus {
  outline: 2px solid var(--color-default);
  outline-offset: 2px;
}
.UploadImage .wrapper .result .ctn-button {
  display: flex;
  justify-content: right;
}
