.ManageImage {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-bottom: 150px;
}
.ManageImage .header {
  margin-bottom: 50px;
}
.ManageImage .header .title {
  color: white;
}

.ManageImage .wrapper .ctn-search {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-default);
}
.ManageImage .wrapper .ctn-search input {
  font-family: var(--font-family);
  width: 100%;
  outline: none;
  border: none;
  background: none;
  color: white;
}
.ManageImage .wrapper .ctn-search .icon {
  margin-right: 10px;
}

.ManageImage .wrapper .result {
  margin-top: 50px;
}
.ManageImage .wrapper .result .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ManageImage .wrapper .result .top .left {
  font-size: 20px;
  font-weight: 500;
}
.ManageImage .wrapper .result .table {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-default);
  margin-top: 20px;
}
.ManageImage .wrapper .result table {
  width: 100%;
}
.ManageImage .wrapper .result table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.ManageImage .wrapper .result table thead th {
  font-weight: 500;
  font-size: 17px;
  padding: 10px;
}
.ManageImage .wrapper .result table tbody {
  padding: 20px 0;
}
.ManageImage .wrapper .result table td {
  cursor: pointer;
  padding: 10px;
}
.ManageImage .wrapper .result table td .ctn-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  margin-left: 10px;
}
.ManageImage .wrapper .result table tr td .ctn-input .check {
  width: 15px;
  height: 15px;
  background-color: var(--color-default);
  border-radius: 5px;
  transform: scale(0);
  transition: all 0.2s;
}
.ManageImage .wrapper .result table tr.active {
  background-color: rgba(255, 255, 255, 0.1);
}
.ManageImage .wrapper .result table tr.active td .ctn-input .check {
  transform: scale(1);
}
.ManageImage .wrapper .result table td select,
.ManageImage .wrapper .result table td input {
  background: #111;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
}
.ManageImage .wrapper .result table td select:focus,
.ManageImage .wrapper .result table td input:focus {
  outline: 2px solid var(--color-default);
  outline-offset: 2px;
}
.ManageImage .wrapper .result table td select .default-tag {
  color: rgba(255, 255, 255, 0.49);
  font-size: 12px;
}
.ManageImage .wrapper .result .ctn-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
