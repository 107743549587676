.NavBar {
  max-width: 1000px;
  padding: 40px;
  padding-bottom: 0;
  margin: auto;
}
.NavBar a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  outline: none;
  transition: all 0.5s;
}
.NavBar a:hover > .icon {
  margin-right: 5px;
}
.NavBar a .icon {
  margin-right: 10px;
  font-size: 13px;
  transition: all 0.5s;
}
.NavBar a .title {
  font-size: 20px;
}
