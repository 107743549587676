@font-face {
  font-family: "Montserrat";
  src: url("../font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Hover";
  src: url("../font/Hover.ttf");
}

:root {
  /* Font */
  --font-family: "Hover", sans-serif;

  /* Color */
  --color-default: rgba(2, 99, 224, 1);
  --color-default-hover: rgb(0, 87, 201);
  --color-default-disabled: rgba(2, 99, 224, 0.9);
  --color-default-text: #171326;
  --color-default-bg: #1c1c1c;

  --color-gray-text: #374151;
  --color-gray-border: #d1d5db;
  --color-gray-bg: #6b7280;

  --color-red: #d30b26;
  --color-red-hover: #c80923;
  --color-red-disabled: #c11f35;
  --color-red-bg: #feedee;
  --color-red-border: #ed8282;

  --color-green: #2eac69;
  --color-green-border: rgb(35, 114, 72) 9;

  --color-yellow: #fee3ac;
  --color-yellow-border: rgb(238, 215, 169);

  --color-text-background: white;

  /* Bg */
  --bg-red: #fcefec;
  --bg-blue: #e8eefa;

  /* Border */
  --border-default: 1px solid #d1d5db;
  --border-red: 1px solid #ecd1c9;
  --border-blue: 1px solid #b6cdec;
  --border-gray: 1px solid #d6d6d6;
  --border-radius-default: 8px;
  --border-radius-little: 6px;
  /* Box-shadow */
  --box-shadow: rgba(255, 255, 255, 0.1) 0px 10px 50px;
  --box-shadow-ctn: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  /* transition */
  --transition: all 0.2s;
}

*::before,
::after {
  font-family: var(--font-family);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--color-default-text);
}

/* G-Y8V1YX6YB8 */

body {
  font-family: var(--font-family);
  margin: 0;
  background-color: #111;
  color: white;
}

.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
  font-weight: 500;
}
.text-xl {
  font-size: 1.25rem;
  font-weight: 500;
}
.text-2xl {
  font-size: 1.5rem;
  font-weight: 500;
}
.text-3xl {
  font-size: 1.875rem;
  font-weight: 700;
}
.text-4xl {
  font-size: 2.25rem;

  font-weight: 700;
}
.text-5xl {
  font-size: 3rem;

  font-weight: 700;
}
.text-6xl {
  font-size: 4rem;
  line-height: 4.2rem;
  font-weight: 600;
}

/* --------------------------- Title ---------------------------- */
/* -------------------------------------------------------------- */

h1,
.h1 {
  font-family: var(--font-family);
  font-size: clamp(2rem, 1.25rem + 6vw, 5rem);
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}
h2,
.h2 {
  font-family: var(--font-family);
  font-size: clamp(1.5rem, 1.1719rem + 2.5vw, 3.125rem);
  font-weight: 600;
  margin: 0;
}
h3,
.h3 {
  font-family: var(--font-family);
  font-size: clamp(1.375rem, 0.8594rem + 4.125vw, 3.4375rem);
  font-weight: 500;
  margin: 0;
}
h4,
.h4 {
  font-family: var(--font-family);
  font-size: clamp(1.25rem, 1.0938rem + 1.25vw, 1.875rem);
  font-weight: 600;
  margin: 0;
}
h5,
.h5 {
  font-family: var(--font-family);
  font-size: clamp(1.0625rem, 1.0156rem + 0.375vw, 1.25rem);
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* -------------------------------------------------------------- */

/* Settings li | a */
li {
  list-style: none;
}

a {
  color: var(--color-default-text);
  font-weight: 500;
  outline: none;
}

/* other */
input[type="submit"] {
  outline: none;
  border: none;
}

input[type="radio"] {
  display: none;
}

.button--Under {
  color: var(--color_gray);
  padding: var(--padding);
  background: none;
  cursor: pointer;
}

.button--Option {
  color: var(--color_primary);
  padding: var(--padding);
  background: none;
  cursor: pointer;
  font-size: 18px;
}

.rows {
  display: flex;
  flex-wrap: wrap;
}
.none {
  outline: none;
  border: none;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}

#test {
  display: block !important;
}

.errorLabelText {
  color: red !important;
  font-style: italic;
  font-weight: 600;
}

.errorData {
  border: 2px solid red !important;
}
