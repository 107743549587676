.ButtonComponent {
  font-family: var(--font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-default);
  border: none;
  color: white;
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius-little);
  transition-property: background-color;
  transition-duration: 0.2s;
}
.ButtonComponent:hover {
  background-color: var(--color-default-hover);
}
.ButtonComponent .icon {
  margin-left: 10px;
}
.ButtonComponent.loadingtrue {
  cursor: not-allowed;
  padding: 3px 20px;
}
.ButtonComponent.iconfalse {
  padding: 8px 20px;
}

.ButtonComponent.loadingtrue .text {
  opacity: 0;
}
.ButtonComponent.loadingtrue .loader {
  position: absolute;
  transform: translateX(calc(-50% + 7.5px));
}
.ButtonComponent .icon-event {
  position: absolute;
  display: flex;
  color: white;
  transform: translateX(calc(-50% + 5.5px));
}

.ButtonComponent.disabled {
  cursor: not-allowed;
}

.ButtonComponent.green {
  background-color: var(--color-green);
}
.ButtonComponent.red {
  background-color: var(--color-red);
}
.ButtonComponent.red:hover {
  background-color: var(--color-red-hover);
}
.ButtonComponent.disabled.red {
  background-color: var(--color-red-disabled);
}
.ButtonComponent.disabled.red:hover {
  background-color: var(--color-red-disabled);
}
.ButtonComponent.blue {
  background-color: var(--color-primary);
}
