.ManageQuote {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-bottom: 150px;
}
.ManageQuote .header {
  margin-bottom: 50px;
}
.ManageQuote .header .title {
  color: white;
}

.ManageQuote .table input {
  width: calc(100% - 40px);
}
