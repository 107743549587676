.Home {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 50px;
}
.Home .header {
  margin-bottom: 50px;
}
.Home .header .title {
  color: white;
}

.Home .wrapper .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-default);
  margin-bottom: 80px;
}
.Home .wrapper .status .left {
  display: flex;
  align-items: center;
}
.Home .wrapper .status .left img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.Home .wrapper .status .left .text .title {
  font-size: 18px;
  font-weight: 500;
}
.Home .wrapper .status .left .text .url {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.713);
}
.Home .wrapper .status .right {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
}
.Home .wrapper .status .right.good {
  color: rgb(0, 190, 0);
}
.Home .wrapper .status .right .icon {
  margin-right: 5px;
}

.Home .wrapper .group {
  margin-bottom: 30px;
}
.Home .wrapper .group .title-header {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}
.Home .wrapper .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: var(--border-radius-default);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  text-decoration: none;
  margin-bottom: 20px;
  outline: none;
  transition: all 0.2s;
}
.Home .wrapper .container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.Home .wrapper .container .left {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Home .wrapper .container .left .icon {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 10px;
}
.Home .wrapper .container .left .title {
  font-size: 18px;
  font-weight: 500;
}
.Home .wrapper .container .left .text {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.713);
}
.Home .wrapper .container .right .icon {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.Home .footer {
  text-align: center;
  margin-top: 80px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}
.Home .footer a {
  cursor: pointer;
  background: none;
  outline: none;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
}
