footer {
  text-align: center;
  margin-top: 80px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}
footer a {
  cursor: pointer;
  background: none;
  outline: none;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
}
