.Auth {
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  transform: translateY(-100px);
  transition: transform 0.3s;
  z-index: 10000000;
}
.Auth.active {
  transform: translateY(0);
  animation: authAnim 0.3s forwards;
  animation-delay: 3s;
}
@keyframes authAnim {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100px);
  }
}
.Auth-inner {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  width: calc(100% - 24px);
  border-radius: 30px;
  background-color: rgba(13, 13, 13, 0.95);
  backdrop-filter: blur(30px);
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.Auth-inner img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.Auth-inner p {
  color: white;
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}
