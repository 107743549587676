.UploadQuote {
  max-width: 1000px;
  padding: 40px;
  margin: auto;
  padding-bottom: 150px;
}
.UploadQuote .header {
  margin-bottom: 50px;
}
.UploadQuote .header .title {
  color: white;
}
.UploadQuote .wrapper .form .ctn-input {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  font-size: 18px;
  margin-bottom: 15px;
}
.UploadQuote .wrapper .form .ctn-input.active {
  outline: 2px solid var(--color-default);
  outline-offset: 2px;
}
.UploadQuote .wrapper .form .ctn-input .icon {
  margin-right: 10px;
}
.UploadQuote .wrapper .form .ctn-input input {
  font-family: var(--font-family);
  width: 100%;
  background: none;
  outline: none;
  border: none;
  color: white;
}

.UploadQuote .wrapper .ctn-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
